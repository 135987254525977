<script>
  import { onMount } from "svelte";
  import Wichtelrunde from "./Wichtelrunde.svelte";
  import { db } from "./firebase";

  export let username;
  export let greeting = "Lieber";
  export let message = "";

  let wichtelrunden = [
    { id: "SJPRixn3tb7BBXzrwQNC" },
    { id: "YFY9aXv0XHCzhVuoV6d8" }
  ];
  let wichtelrundenLookup = {};

  onMount(() => {
    wichtelrunden.forEach(wichtelrunde => {
      wichtelrundenLookup[wichtelrunde.id] = wichtelrunde;
    });

    wichtelrunden.forEach(wichtelrunde => {
      db.doc("Wichtelrunden/" + wichtelrunde.id).onSnapshot(doc => {
        wichtelrunde.title = doc.data().title;
        wichtelrunden = wichtelrunden; // notify change
      });

      wichtelrunde.participants = [];
      wichtelrunde.alreadyChosenParticipants = [];
      wichtelrunde.choosings = {};
      db.collection(
        "Wichtelrunden/" + wichtelrunde.id + "/Participants"
      ).onSnapshot(querySnapshot => {
        querySnapshot.docs.forEach(doc => {
          if (doc.id == username) {
            wichtelrunde.choice = doc.data().choice;
            if (wichtelrunde.choice === undefined) {
              wichtelrunde.choice = "";
            }
          } else {
            wichtelrunde.choosings[doc.id] = doc.data().choice;

            wichtelrunde.participants = Object.keys(wichtelrunde.choosings);
            wichtelrunde.alreadyChosenParticipants = Object.values(
              wichtelrunde.choosings
            );
          }
          wichtelrunden = wichtelrunden; // notify change
        });
      });
    });
  });

  function handleChosen(e) {
    message =
      username +
      " has chosen " +
      (e.detail.choice ? e.detail.choice : "no one") +
      " in Wichtelrunde " +
      e.detail.id;

    db.doc("Wichtelrunden/" + e.detail.id + "/Participants/" + username).update(
      {
        choice: e.detail.choice
      }
    );
  }
</script>

<h4>{greeting} {username}, wen hast du gezogen?</h4>

{#each wichtelrunden as wichtelrunde}
  {#if wichtelrunde.choice !== undefined}
    <Wichtelrunde
      id={wichtelrunde.id}
      title={wichtelrunde.title}
      participants={wichtelrunde.participants}
      alreadyChosenParticipants={wichtelrunde.alreadyChosenParticipants}
      choice={wichtelrunde.choice}
      on:choose={handleChosen} />
  {/if}
{/each}
