<script>
  import Chip, { Set } from "@smui/chips";
  import { createEventDispatcher } from "svelte";
  import { fade, fly } from "svelte/transition";

  const dispatch = createEventDispatcher();

  export let id;
  export let title = "Wichtelrunde";
  export let participants = [];
  export let alreadyChosenParticipants = [];
  export let choice;

  $: mineAlreadyChosen =
    choice &&
    alreadyChosenParticipants.filter(p => p).length > 4 &&
    alreadyChosenParticipants.some(p => p == choice);

  $: dispatch("choose", {
    id: id,
    choice: choice
  });
</script>

<h5>{title}</h5>

<Set chips={participants} let:chip choice bind:selected={choice}>
  <Chip tabindex="0">{chip}</Chip>
</Set>

{#if mineAlreadyChosen}
  <p in:fly={{ x: -200, duration: 1000 }}>
    Bist sicher? Irgend a anderer hot {choice} a scho?! 🤔
  </p>
{:else if choice}
  <p in:fade>Du hast {choice} ausgewählt, danke fürs mitmachen! 👍</p>
{:else}
  <p>&nbsp;</p>
{/if}
