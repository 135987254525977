import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
var firebaseConfig = {
    apiKey: "AIzaSyB5NTEKHiu_o28csndeFNiMOH0voCR12Ns",
    authDomain: "wichtelrunde-35a4b.firebaseapp.com",
    databaseURL: "https://wichtelrunde-35a4b.firebaseio.com",
    projectId: "wichtelrunde-35a4b",
    storageBucket: "wichtelrunde-35a4b.appspot.com",
    messagingSenderId: "653777956918",
    appId: "1:653777956918:web:06ea25827c759d0b38e897",
    measurementId: "G-L3PYC2E51K"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const emailAuthProvider = new firebase.auth.EmailAuthProvider();

export const db = firebase.firestore();
