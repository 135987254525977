<script>
  import Login from "./src/Login.svelte";
  import Wichtelrunden from "./src/Wichtelrunden.svelte";

  let user;
  let username;
  let greeting = "";

  let message = "";

  let videoUrls = [
    "https://drive.google.com/uc?export=download&id=1AOPiv7TS7rGTAKuvjfvX5z6yArUVABsd",
    "https://drive.google.com/uc?export=download&id=1xSE5oNkeotb-I7N9CxltUOJxnCkcZ7Kn",
    "https://drive.google.com/uc?export=download&id=1TukDMpY8ebQB6Al0xGkCAa2IwA_9m7CU",
    "https://drive.google.com/uc?export=download&id=1xSE5oNkeotb-I7N9CxltUOJxnCkcZ7Kn",
    "http://sveltejs.github.io/assets/caminandes-llamigos.mp4",
    "https://static.videezy.com/system/resources/previews/000/017/998/original/christmas5_1.mp4"
  ];
  let currentBackgroundVideoUrlIndex = 0;
  $: backgroundVideoUrl =
    videoUrls[currentBackgroundVideoUrlIndex % videoUrls.length];
</script>

<style>
  #backgroundVideo {
    position: fixed;
    left: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1000;
  }
</style>

<div style="padding: 30px;">
  <Login bind:user bind:username bind:greeting />

  <!--<p>{message}</p>-->

  {#if user}
    <Wichtelrunden bind:message {username} {greeting} />
  {/if}
</div>

<!--
<video
  autoplay
  muted
  loop
  playsinline
  id="backgroundVideo"
  src={backgroundVideoUrl} />
<!-- poster="http://sveltejs.github.io/assets/caminandes-llamigos.jpg" -->
