<script>
  import Textfield, { Input, Textarea } from "@smui/textfield";
  import Icon from "@smui/textfield/icon/index";
  import HelperText from "@smui/textfield/helper-text/index";
  import CharacterCounter from "@smui/textfield/character-counter/index";
  import { Icon as CommonIcon } from "@smui/common";
  import FloatingLabel from "@smui/floating-label";
  import LineRipple from "@smui/line-ripple";
  import NotchedOutline from "@smui/notched-outline";

  import Button, { Group, GroupItem, Label } from "@smui/button";

  import Card, { Content } from "@smui/card";
  import "../elevation.scss";

  import { fade, fly } from "svelte/transition";

  import { onMount } from "svelte";
  import { auth, emailAuthProvider } from "./firebase";
  import { authState } from "rxfire/auth";

  let email = "";
  let password = "";
  let errorMessage = "";

  export let user;
  export let username;
  export let greeting;
  $: username = getUsernameForEmail(user ? user.email : "");
  $: greeting = getGreetingForEmail(user ? user.email : "");

  onMount(() => {
    const unsubscribe = authState(auth).subscribe(u => (user = u));
  });

  function login() {
    auth.signInWithEmailAndPassword(email, password).catch(function(error) {
      errorMessage = error.message;
    });
  }

  function getUsernameForEmail(email) {
    switch (email) {
      case "matthias.prinz@gmx.at":
        return "Matthias";
      case "zzlaurab@gmail.com":
        return "Laura";
      case "steve@516.at":
        return "Stefan";
      case "vici@516.at":
        return "Vici";
      case "hermi@516.at":
        return "Hermi";
      case "peter@516.at":
        return "Peter";
      case "sandi@516.at":
        return "Sandi";
      case "roni@516.at":
        return "Roni";
      case "valerie@516.at":
        return "Valerie";
      case "johanna@516.at":
        return "Johanna";
    }
    return "Unknown User";
  }

  function getGreetingForEmail(email) {
    switch (email) {
      case "matthias.prinz@gmx.at":
        return "Lieber";
      case "zzlaurab@gmail.com":
        return "Liebe";
      case "steve@516.at":
        return "Lieber";
      case "vici@516.at":
        return "Liebe";
      case "hermi@516.at":
        return "Liebe";
      case "peter@516.at":
        return "Lieber";
      case "sandi@516.at":
        return "Liebe";
      case "roni@516.at":
        return "Lieber";
      case "valerie@516.at":
        return "Liebe";
      case "johanna@516.at":
        return "Liebe";
    }
    return "Liebe";
  }
</script>

<style>
  .card-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
</style>

{#if !user}
  <div class="card-container">
    <Card class="mdc-elevation--z5" padded>
      <Textfield
        variant="outlined"
        bind:value={email}
        label="eMail"
        type="email"
        input$aria-controls="helper-text-email"
        input$aria-describedby="helper-text-email" />
      <HelperText id="helper-text-email">
        Die von Matthias erhaltene eMail Adresse
      </HelperText>

      <Textfield
        variant="outlined"
        bind:value={password}
        label="Passwort"
        type="password"
        input$aria-controls="helper-text-password"
        input$aria-describedby="helper-text-password" />
      <HelperText id="helper-text-password">
        Bitte gib dein Passwort ein
      </HelperText>

      <Button on:click={login} variant="unelevated">
        <Label>Login</Label>
      </Button>

      {#if errorMessage}
        <p style="color:red;">{errorMessage}</p>
      {/if}

    </Card>
  </div>
{:else}
  <div style="text-align: right;" in:fade>
    <Button on:click={() => auth.signOut()} variant="outlined">
      <Label>Logout</Label>
    </Button>
  </div>
{/if}
